import { useEffect, useState } from "react";
import { usePOST } from "../../Tools/APIs/useMyAPI";
import { Col, Row } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading/Loading";
import { MainButton } from "../../components";
import Input from "../../components/Input/Input";
function SetPassword() {
  const [password, setPassword] = useState(false);
  const { setFormData, handleSubmit, loading, handleChangeInput, formData } =
    usePOST({});
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit("admin/reset-password", "/login");
  };

  useEffect(() => {
    setFormData({
      ...formData,
      verification_code: sessionStorage.getItem("code"),
      email: sessionStorage.getItem("email"),
    });
  }, []);
  return (
    <div className="fixed w-full h-full bg-[#FFF4F4] top-0 left-0 z-50 flex items-center justify-center">
      <Col
        md={6}
        sm={11}
        className="mx-auto bg-white p-8 rounded-3xl border-t shadow-xl"
      >
        <Row className="flex justify-center items-center">
          <h1 className="text-2xl font-semibold text-center">كلمة السر</h1>
          <Col md={10} className="mx-auto">
            <Input
              name="password"
              type={!password ? "password" : "text"}
              title="أدخل كلمة المرور"
              onChange={handleChangeInput}
              className="bg-white w-full border-[3px] my-1 !py-3 rounded-xl"
            />
          </Col>
          <Col md={10} className="mx-auto">
            <Input
              name="password_confirmation"
              type={!password ? "password" : "text"}
              title="تأكيد كلمة المرور"
              onChange={handleChangeInput}
              className="bg-white w-full border-[3px] my-1 !py-3 rounded-xl"
            />
          </Col>
          <div className="text-lg mt-5 flex items-center">
            <input
              type="checkbox"
              onClick={() => setPassword(!password)}
              name="checkbox"
              className="mx-3 w-5 h-5"
            />
            <span>إظهار كلمة المرور</span>
          </div>
          {loading ? <Loading /> : ""}
          <Col md={8} xs={8} className="mx-auto">
            <MainButton
              name="تأكيد كلمة المرور"
              className="!px-10 mt-2 !py-2"
              onClick={handleSubmitMain}
            />
          </Col>
        </Row>
      </Col>
    </div>
  );
}
export default SetPassword;
