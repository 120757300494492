import React from "react";
import { Col, Row } from "../../Tools/Grid-system";
import { MainButton, Slider, Swipper, Title } from "../../components";
import { SiLinkedin } from "react-icons/si";
import { FaFacebook } from "react-icons/fa";
import { SiTelegram } from "react-icons/si";
import { SiGithub } from "react-icons/si";
import { useParams } from "react-router-dom";
import { useFETCH } from "../../Tools/APIs/useMyAPI";
import parse from "html-react-parser";

function Service() {
  const { id } = useParams();

  const { data, isLoading, deleteItem } = useFETCH(`admin/services/${id}`);
  let dataAll = data?.data.data;

  return (
    <>
      <Row>
        <Col className="flex justify-center items-center  ">
          <Title name="خدماتنا " />
        </Col>
        <Row className="mx-auto">
          <Col
            lg={11}
            className="mx-auto pt-5 flex justify-center items-center "
          >
            <Slider data={data} />
          </Col>
        </Row>
        <Row className="mx-auto ">
          <Col md={11} className="mx-auto  mb-12 space-y-5">
            <p className="text-yellow-500 text-start font-bold text-2xl mx-auto max-md:text-sm">
              روايط الخدمة
            </p>
          </Col>
        </Row>
        {/* <Row className="mx-auto mb-8">
          <Col md={11} className="mx-auto pt-5 flex justify-center gap-4 ">
            <SiLinkedin color="yellow" size={40} className="rounded-full" />
            <FaFacebook color="yellow" size={40} className="rounded-full" />
            <SiTelegram color="yellow" size={40} className="rounded-full" />
            <SiGithub color="yellow" size={40} className="rounded-full" />
          </Col>
        </Row> */}

        <Row className="mx-auto ">
          <Col md={11} className="mx-auto  mb-12 space-y-5">
            <p className="text-yellow-500 text-end font-bold text-2xl mx-auto ">
              Service Name: {dataAll?.en_name && parse(dataAll?.en_name)}
            </p>
            <p className="text-white text-end font-bold text-xl  ">
              {dataAll?.en_description && parse(dataAll?.en_description)}
            </p>
          </Col>
        </Row>

        <Row className="mx-auto">
          <Col md={11} className="mx-auto  space-y-5">
            <p className="text-yellow-500 text-start font-bold text-2xl mx-auto   ">
              اسم الخدمة: {dataAll?.ar_name && parse(dataAll?.ar_name)}
            </p>
            <p className="text-white text-start font-bold text-xl  ">
              {dataAll?.ar_description && parse(dataAll?.ar_description)}
            </p>
          </Col>
        </Row>

        <Col md={5} className="mx-auto !mt-12 ">
          <MainButton name="تعديل" link={`/our-services/add/${id}`} />
        </Col>
      </Row>
    </>
  );
}

export default Service;
