import { Link } from "react-router-dom";

function ButtonCard({ button, onClick, className }) {
  return (
    <Link to={`${button || ""}`}>
      <div>
        <button
          className={`bg-Main rounded-xl w-[80%] hover:bg-opacity-70 text-center text-lg font-bold text-white cursor-pointer px-5 py-3 flex justify-center mx-auto max-md:text-base ${className}`}
          onClick={onClick}
        >
          Show More
        </button>
      </div>
    </Link>
  );
}

export default ButtonCard;
