import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import { clickZoomInImage, fileUrl, useFETCH } from "../../Tools/APIs/useMyAPI";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import l1 from "../../images/home.jpg";

import { Navigation, Pagination } from "swiper/modules";
import { Col, Row } from "../../Tools/Grid-system";
import { useParams } from "react-router-dom";

SwiperCore.use([Navigation, Pagination]);

const Slider = ({ data }) => {
  const { id } = useParams();

  // const { data: datablo } = useFETCH(`admin/blogs/${id}`);
  console.log(data);

  const [clickedImage, setClickedImage] = useState(null);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const handleImageClick = (image) => {
    setClickedImage(image);
    setIsFullScreen(true);
  };

  const handleCloseFullScreen = () => {
    setIsFullScreen(false);
  };

  return (
    <>
      <Swiper
        dir="rtl"
        navigation={true}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          1020: {
            slidesPerView: 3,
          },
          560: {
            slidesPerView: 2,
          },
          0: {
            slidesPerView: 1,
          },
        }}
        modules={[Navigation, Pagination]}
        className="!mb-16 !bg-transparent !flex !justify-center !w-[900px] !shadow-lg !shadow-white !h-[300px]"
      >
              {data?.data?.data?.files.map((file, key) => (
          <SwiperSlide key={key} className="p-4">
            {file.type === "image" && (
              <img
                src={fileUrl + file.name}
                alt=""
                className="w-full h-full shadow-lg shadow-white"
                onClick={() => handleImageClick(fileUrl + file.name)}
              />
            )}
            {file.type === "video" && (
              <video controls className="w-full h-full shadow-lg shadow-white">
                <source src={fileUrl + file.name} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
          </SwiperSlide>
        ))}
        {/* {datablo?.data?.data?.files.map((file, key) => (
          <SwiperSlide key={key} className="p-4">
            {file.type === "image" && (
              <img
                src={fileUrl + file.name}
                alt=""
                className="w-full h-full shadow-lg shadow-white"
                onClick={() => handleImageClick(fileUrl + file.name)}
              />
            )}
            {file.type === "video" && (
              <video controls className="w-full h-full shadow-lg shadow-white">
                <source src={fileUrl + file.name} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
          </SwiperSlide>
        ))} */}
      </Swiper>

      {isFullScreen && (
        <div
          className="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-80 z-50 flex justify-center items-center"
          onClick={handleCloseFullScreen}
        >
          {clickedImage && clickedImage.type === "image" && (
            <img
              src={clickedImage}
              alt=""
              className="max-w-[90%] max-h-[90%] object-contain shadow-lg shadow-white"
            />
          )}
          {clickedImage && clickedImage.type === "video" && (
            <video
              controls
              className="max-w-[90%] max-h-[90%] object-contain shadow-lg shadow-white"
            >
              <source src={clickedImage} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
        </div>
      )}
    </>
  );
};

export default Slider;
