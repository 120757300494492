import { Col, Row } from "../../Tools/Grid-system";
import Table from "../../components/Table/Table";
import l_1 from "../../images/Image.png";
import l_2 from "../../images/Image (1).png";
import l_3 from "../../images/Image (2).png";
import { MainButton, Slider, Title } from "../../components";
import React from "react";
import { SiLinkedin } from "react-icons/si";
import { FaFacebook } from "react-icons/fa";
import { SiTelegram } from "react-icons/si";
import { SiGithub } from "react-icons/si";
import { fileUrl, useFETCH } from "../../Tools/APIs/useMyAPI";
import { useParams } from "react-router-dom";
import Loading from "../../Tools/Loading/Loading";
import parse from "html-react-parser";

function Blog() {
  const { id } = useParams();
  const { data, isLoading } = useFETCH(
    id ? `admin/blogs/${id}` : `admin/blogs`
  );

  // const dataAll = data?.data?.data?.map((e) => {
  //   return {
  //     id: e?.id,
  //     image: e.image,
  //     ar_title: e.ar_title,
  //     en_title: e.en_title,
  //     ar_description: e.ar_description,
  //     en_description: e.en_description,
  //     category: e.category,
  //   };
  // });
  let dataAll = data?.data?.data;
  console.log(dataAll);

  return (
    <>
      <Row>
        <Col className="flex justify-center items-center  ">
          <Title name="المقالات" />
        </Col>
        {isLoading ? <Loading /> : ""}
        <Row className="mx-auto mb-5">
          <Col md={11} className="mx-auto pt-5 flex justify-center">
            <img src={fileUrl + dataAll?.image} alt="" />
          </Col>
          <Row className="mx-auto pt-5 flex justify-center">
            <Col
              md={11}
              className="mx-auto  mb-12 space-y-5 flex justify-start"
            >
              <p className="text-yellow-500 text-start font-bold text-2xl max-md:text-sm  ">
                اصناف المقال
              </p>
            </Col>
            <Col md={11} className="  space-y-5 flex  mt-12 ">
              <p className="text-white text-start font-bold text-2xl max-md:text-sm  ">
                صنف المقال بالعربي : {dataAll?.category?.ar_name}
              </p>
            </Col>
            <Col md={11} className="  space-y-5 flex justify-end mt-12 ">
              <p className="text-white text-end font-bold text-2xl max-md:text-sm ">
                Classify the article in Arabic : {dataAll?.category?.en_name}
              </p>
            </Col>
          </Row>
        </Row>
        <Row className="mx-auto !mt-5 ">
          <Col md={11} className="mx-auto  mb-12 space-y-5">
            <p className="text-yellow-500 text-start font-bold text-2xl mx-auto ">
              روابط المقال
            </p>
          </Col>
        </Row>
        {/* <Row className="mx-auto mb-8">
          <Col md={11} className="mx-auto pt-5 flex justify-center gap-4 ">
            <SiLinkedin color="yellow" size={40} className="rounded-full" />
            <FaFacebook color="yellow" size={40} className="rounded-full" />
            <SiTelegram color="yellow" size={40} className="rounded-full" />
            <SiGithub color="yellow" size={40} className="rounded-full" />
          </Col>
        </Row> */}

        <Row className="mx-auto">
          <Col md={11} className="mx-auto pt-1 space-y-5 mb-12">
            <p className="text-yellow-500 text-end font-bold text-2xl mx-auto ">
              blog Name: {dataAll?.en_title}
            </p>
            <p className="text-white text-end font-bold text-xl">
              {dataAll?.en_description && parse(dataAll?.en_description)}
            </p>
          </Col>
        </Row>
        <Row className="mx-auto">
          <Col md={11} className="mx-auto pt-1 space-y-5">
            <p className="text-yellow-500 text-start font-bold text-2xl mx-auto ">
              اسم المقالة: {dataAll?.ar_title}
            </p>
            <p className="text-white text-start font-bold text-xl">
              {dataAll?.ar_description && parse(dataAll?.ar_description)}
            </p>
          </Col>
        </Row>

        <Col md={5} className="mx-auto !mt-12">
          <MainButton name="تعديل" link={`/blogs/add/${id}`} />
        </Col>
      </Row>
    </>
  );
}

export default Blog;
