import { Col, Row } from "../../Tools/Grid-system";
import Table from "../../components/Table/Table";
import l_1 from "../../images/Image.png";
import l_2 from "../../images/Image (1).png";
import l_3 from "../../images/Image (2).png";
import { Add, Title } from "../../components";
import useFilter, { useFETCH } from "../../Tools/APIs/useMyAPI";
import Loading from "../../Tools/Loading/Loading";

function Blogs() {
  const formatDateTime = (dateString) => {
    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    const formattedTime = ` ${hours}:${minutes}:${seconds}`;

    return [`${formattedTime}`, `${formattedDate}`];
  };
  const { searchParams } = useFilter();

  const { data, deleteItem, isLoading } = useFETCH(
    `admin/blogs?page=${searchParams.get("page")}`,
    `admin/blogs`
  );

  const dataAll = data?.data?.data?.map((e) => {
    return {
      id: e?.id,
      image: e.image,
      ar_title: e.ar_title,
      en_title: e.en_title,
      ar_description: e.ar_description,
      en_description: e.en_description,
      date: formatDateTime(e?.created_at)[1],
      time: formatDateTime(e?.created_at)[0],
    };
  });

  // console.log(data);

  return (
    <>
      <Row>
        <Col className="flex justify-center">
          <Title name="المقالات" />
        </Col>
      </Row>
      {isLoading ? <Loading /> : ""}
      <Row>
        <Col>
          <Table
            thead={[
              "رقم المقالة",
              "صورة المقالة",
              "  اسم المقالة بالعربي",
              " اسم المقالة بالانكليزي",

              " وصف المقالة بالعربي",
              " وصف المقالة بالانكليزي",

              " تاريخ  المقالة",
              "   وقت المقالة",
            ]}
            pageCount={Math.ceil(data?.data?.total / data?.data?.per_page)}
            tData={dataAll}
            tbody={[
              "id",
              "img",
              "ar_title",
              "en_title",
              "en_description",
              "ar_description",
              "date",
              "time",
            ]}
            funDelete={deleteItem}
            edit={true}
            add="blogs/add"
            show={`blogs`}
            description={dataAll?.map((e) => e.ar_title)}
          />
        </Col>
      </Row>
      <Add link="add" />
    </>
  );
}
export default Blogs;
