import React, { useEffect, useState } from "react";
import { Col, Row } from "../../Tools/Grid-system";
import { Back, MainButton, Title } from "../../components";
import JoditEditor from "jodit-react";
import { fileUrl, useFETCH, usePOST } from "../../Tools/APIs/useMyAPI";
import { useParams } from "react-router-dom";
import AddImage from "../../Tools/AddImage/AddImage";

function AddOurJobs() {
  const { id } = useParams();
  const { data } = useFETCH(`admin/info`);

  const [oldImages, setoldImages] = useState({});

  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit(`admin/info/update`);
  };

  let dataOld = data?.data?.about;
  let dataOld_1 = data?.data?.our_job;
  let dataOld_2 = data?.data?.about?.about1_text;
  let dataOld_3 = data?.data?.our_job?.our_job_text;

  // const [userImages, setUserImages] = useState(dataOld?.data?.data?.images);

  const [userImage, setUserImage] = useState(dataOld);
  const [userImage_1, setUserImage_1] = useState(dataOld_1);

  // useEffect(() => {
  //   setoldImage(dataOld?.files);
  // }, []);

  useEffect(() => {
    setFormData({
      about1_text_ar: dataOld_2?.ar || "",
      about1_text_en: dataOld_2?.en || "",
      our_job_text_ar: dataOld_3?.ar || "",
      our_job_text_en: dataOld_3?.en || "",
    });
  }, [dataOld_2, dataOld_3]);

  // useEffect(() => {
  //   if (successfulPost) {
  //     setImages({});
  //     setCounter(0);
  //     setFormData({});
  //   }
  // }, [successfulPost]);

  useEffect(() => {
    setUserImage(dataOld?.about1_image);
    setUserImage_1(dataOld_1?.our_job_image);
  }, [dataOld, dataOld_1]);

  const {
    setFormData,
    viewImages,
    setViewImages,
    handleChangeArrayImages,
    handleChangeInput,
    handleSubmit,
    setImages,
    formData,
    loading,
    counter,
    setCounter,
    successfulPost,
    images,
    viewFile,
    setViewFile,
    handleChangeInput_1,
    view,
    setView,
  } = usePOST();
  return (
    <>
      <Row>
        <Col className="flex justify-center">
          <Title name="معلومات عنا " />
        </Col>
      </Row>
      <Row>
        <Col lg={11} className="mx-auto">
          <h1 className="mb-4 text-2xl text-white font-bold text-start max-md:text-lg">
            أضف صورة معلومات اعمالنا{" "}
          </h1>
          <AddImage
            name="our_job_image"
            newImage={viewFile}
            oldImage={userImage_1 && fileUrl + userImage_1}
            clickDeleteOldImage={() => {
              setUserImage_1("");
            }}
            onChange={handleChangeInput}
            clickDeleteImage={() => {
              setViewFile("");
              setFormData({ ...formData, our_job_image: "" });
            }}
          />
        </Col>
        <Col lg={11} className="mx-auto pb-5">
          <h1 className="mb-4 text-2xl text-white font-bold text-start max-md:text-lg">
            وصف معلومات اعمالنا باللغة الانكليزية
          </h1>
          <JoditEditor
            value={formData?.our_job_text_en}
            onChange={(e) => setFormData({ ...formData, our_job_text_en: e })}
          />
        </Col>
        <Col lg={11} className="mx-auto pb-5">
          <h1 className="mb-4 text-2xl text-white font-bold text-start max-md:text-lg">
            وصف معلومات اعمالنا باللغة العربية
          </h1>
          <JoditEditor
            value={formData?.our_job_text_ar}
            onChange={(e) => setFormData({ ...formData, our_job_text_ar: e })}
          />
        </Col>
      </Row>
      <Row className="flex justify-center mx-auto">
        <Col md={2}>
          <Back />
        </Col>
        <Col md={2}>
          <MainButton name="حفظ" onClick={handleSubmitMain} />
        </Col>
      </Row>
    </>
  );
}

export default AddOurJobs;
