import { Add, CardAll, Title } from "../../components";
import { Col, Row } from "../../Tools/Grid-system/index";

import useFilter, { fileUrl, useFETCH } from "../../Tools/APIs/useMyAPI";
import Loading from "../../Tools/Loading/Loading";
import Pagination from "../../Tools/Pagination";

function Services() {
  const { searchParams } = useFilter();
  const { data, isLoading, deleteItem } = useFETCH(
    `admin/services?page=${searchParams.get("page")}`,
    `admin/services`
  );

  let dataAll = data?.data.data;

  return (
    <>
      <Row>
        <Col className="flex justify-center">
          <Title name="خدماتنا" />
        </Col>
      </Row>
      {isLoading ? <Loading /> : ""}

      {dataAll === 0 ? (
        <div className="h-[73vh] flex justify-center items-center text-2xl font-semibold text-center">
          <p className="border-2 border-Secondary rounded-xl px-3 py-8 bg-white">
            لا يوجد عروض
          </p>
        </div>
      ) : (
        <Pagination
          pageCount={Math.ceil(data?.data.total / data?.data.per_page)}
        >
          <Row className=" flex justify-center ">
            {dataAll?.map((e, key) => (
              <Col lg={4} md={6} key={key} className="mb-6">
                <CardAll
                  image={fileUrl + e.image}
                  text="عرض التفاصيل"
                  deleteClick={() => deleteItem(e)}
                  edit={`add/${e.id}`}
                  p={e.en_description.substring(0, 100) + "..."}
                  title={e.en_name}
                  title_ar={e.ar_name}
                  p_ar={e.ar_description.substring(0, 100) + "..."}
                  buttoncard={`/our-services/${e.id}`}
                  description={""}
                ></CardAll>
              </Col>
            ))}
          </Row>
        </Pagination>
      )}

      <Add link="add" />
    </>
  );
}
export default Services;
