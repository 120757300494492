import React from "react";
import { Col, Row } from "../../Tools/Grid-system";
import { Table, Title } from "../../components";
import l_1 from "../../images/home-image-1 1.png";
import l_2 from "../../images/home.jpg";
import { SiLinkedin } from "react-icons/si";
import { FaFacebook } from "react-icons/fa";
import { SiInstagram } from "react-icons/si";
import { SiAppstore } from "react-icons/si";
import { CgWebsite } from "react-icons/cg";
import { SiGoogleplay } from "react-icons/si";

import { MainButton, Slider, Swipper } from "../../components";
import { useFETCH } from "../../Tools/APIs/useMyAPI";
import { Link, useParams } from "react-router-dom";
import parse from "html-react-parser";

function Project() {
  const { id } = useParams();
  const { data } = useFETCH(`admin/projects/${id}`);

  let dataAll = data?.data?.data;
  // console.log(data);
  return (
    <>
      <Row>
        <Col className="flex justify-center items-center  ">
          <Title name="مشاريعنا   " />
        </Col>
        <Row className="mx-auto">
          <Col md={11} className="mx-auto pt-5 ">
            <Slider data={data} />
          </Col>
        </Row>
        <Row className="mx-auto !mt-5 ">
          <Col md={11} className="mx-auto  mb-12 space-y-5">
            <p className="text-yellow-500 text-start font-bold text-2xl mx-auto ">
              روابط المشروع
            </p>
          </Col>
        </Row>
        <Row className="mx-auto mb-8">
          <Col md={11} className="mx-auto pt-5 flex justify-center gap-4 ">
            {dataAll?.facebook && (
              <a href={dataAll.facebook} target="_blank" rel="noreferrer">
                <FaFacebook color="yellow" size={40} className="rounded-full" />
              </a>
            )}
            {dataAll?.linkedin && (
              <a href={dataAll.linkedin} target="_blank" rel="noreferrer">
                <SiLinkedin color="yellow" size={40} className="rounded-full" />
              </a>
            )}
            {dataAll?.instagram && (
              <a href={dataAll.instagram} target="_blank" rel="noreferrer">
                <SiInstagram
                  color="yellow"
                  size={40}
                  className="rounded-full"
                />
              </a>
            )}
            {dataAll?.app_store && (
              <a href={dataAll.app_store} target="_blank" rel="noreferrer">
                <SiAppstore color="yellow" size={40} className="rounded-full" />
              </a>
            )}
            {dataAll?.web && (
              <a href={dataAll.web} target="_blank" rel="noreferrer">
                <CgWebsite color="yellow" size={40} className="rounded-full" />
              </a>
            )}
            {dataAll?.google_play && (
              <a href={dataAll.google_play} target="_blank" rel="noreferrer">
                <SiGoogleplay
                  color="yellow"
                  size={40}
                  className="rounded-full"
                />
              </a>
            )}
          </Col>
        </Row>

        <Row className="mx-auto mb-5">
          <Col md={11} className="mx-auto pt-1 space-y-5 mb-12">
            <p className="text-yellow-500 text-end font-bold text-2xl mx-auto ">
              project Name:{dataAll?.en_name}
            </p>
            <p className="text-white text-end font-bold text-xl">
              {dataAll?.en_description && parse(dataAll?.en_description)}
            </p>
          </Col>
        </Row>
        <Row className="mx-auto">
          <Col md={11} className="mx-auto pt-1 space-y-5">
            <p className="text-yellow-500 text-start font-bold text-2xl mx-auto ">
              اسم المشروع: {dataAll?.ar_name}
            </p>
            <p className="text-white text-start font-bold text-xl">
              {dataAll?.ar_description && parse(dataAll?.ar_description)}
            </p>
          </Col>
        </Row>

        <Col md={5} className="mx-auto !mt-12">
          <MainButton name="تعديل" link={`/our-project/add/${id}`} />
        </Col>
      </Row>
    </>
  );
}

export default Project;
