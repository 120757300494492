import { Link } from "react-router-dom";
import { useLOGIN } from "../../Tools/APIs/useMyAPI";
import { Col, Row } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading/Loading";
import { Input, MainButton, PasswordInput } from "../../components";
import Logo from "../../images/Logo.png";

const Login = () => {
  const { handleSubmit, loading, handleChange, error } = useLOGIN();
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit("admin/login");
  };

  return (
    <div className="fixed w-full h-full bg-[#FFF4F4] top-0 left-0 z-50 flex items-center justify-center">
      <Col
        md={6}
        sm={11}
        className="mx-auto space-y-3 bg-white p-8 rounded-3xl border-t shadow-xl"
      >
        <img src={Logo} alt="" className="mx-auto object-cover" />
        <p className="text-xl font-semibold text-center my-2">
          سجل دخول حتى تتمكن من الوصول الى اللوحة
        </p>
        <Row>
          <Col md={10} className="mx-auto">
            <Input
              type="text"
              name="email"
              onChange={handleChange}
              className="bg-white w-full  my-1 !py-3 rounded-xl"
              placeholder="الايميل"
            />
          </Col>
          <Col md={10} className="mx-auto">
            <PasswordInput
              name="password"
              onChange={handleChange}
              className="bg-white w-full border-[3px] my-1 !py-3 rounded-xl"
              top="top-4"
              placeholder=" كلمة المرور"
              error={error}
            />
            <div className="text-red-500 font-semibold text-center">
              {error?.error}
            </div>
            {/* <Link
              to="/forget-password"
              className="underline text-whit text-start cursor-pointer"
            >
              هل نسيت كلمة المرور
            </Link> */}
          </Col>
          <Col md={5} sm={8} className="mx-auto">
            <MainButton
              name="إرسال"
              className="!px-10 mt-2 !py-2"
              onClick={handleSubmitMain}
            />
          </Col>
        </Row>
        {loading ? <Loading /> : ""}
      </Col>
    </div>
  );
};

export default Login;
