import { useState } from "react";
import { AiOutlineCloudUpload } from "react-icons/ai";

function InputBox() {
  const [imageLinkObjects, setImageLinkObjects] = useState([]);
  const [images, setImages] = useState([]);
  const [links, setLinks] = useState([]);

  const handleAddObject = () => {
    if (
      images.length > 0 &&
      links.length > 0 &&
      images.length === links.length
    ) {
      const newImageLinkObject = { images, links };
      setImageLinkObjects((prevImageLinkObjects) => [
        ...prevImageLinkObjects,
        newImageLinkObject,
      ]);
      setImages([]);
      setLinks([]);
    }
  };

  const handleAddImage = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      setImages((prevImages) => [...prevImages, event.target.result]);
    };

    reader.readAsDataURL(file);
  };

  const handleAddLink = (e, index) => {
    const newLinks = [...links];
    newLinks[index] = e.target.value;
    setLinks(newLinks);
  };
  console.log(imageLinkObjects);

  return (
    <div className="bg-transparent text-Main border-2 border-Main rounded-xl flex justify-center items-center pt-3">
      <div className="">
        <input
                name="file"
                type="file"
                className="border-2 border-Secondary py-4 rounded-2xl my-3 w-full"
                placeholder="كلمة المرور الجديدة"
                onChange={handleAddImage}
                accept="image/*"
              />
        {images.map((image, index) => (
          <div key={index} className="flex justify-center items-center my-4 gap-10">
            <div className="border-2 border-yellow-500 rounded-full w-[20%] h-[20%]">
              <img
                src={image}
                alt={`Image ${index + 1}`}
                className="w-full h-full mx-auto"
              />
            </div>
            <div className="border-2 border-yellow-500 rounded-full  ">
              <input
                type="text"
                placeholder="Link URL"
                value={links[index] || ""}
                onChange={(e) => handleAddLink(e, index)}
                className="!bg-[#111111] rounded-xl"
              />
            </div>
          </div>
        ))}
        <div className="flex justify-center">

        <button
          onClick={handleAddObject}
          disabled={images.length !== links.length}
          className="bg-transparent text-Main border-2 border-yellow-500 my-5 rounded-xl mx-auto p-2"
        >
          Add Object
        </button>
        </div>
      </div>
      <div className="flex-col ">
        {imageLinkObjects.map((imageLinkObject, index) => (
          <div key={index} className="flex justify-center ">
            {imageLinkObject.images.map((image, idx) => (
              <div key={idx} className="p-2 border-2 border-yellow-500 m-2 rounded-xl flex justify-center ">
                <img src={image} alt={`Image ${idx + 1}`}  className="" />
                <a href={imageLinkObject.links[idx]}>Link</a>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default InputBox;
