import { Col, Row } from "../../Tools/Grid-system";
import { Back, Input, MainButton, Title } from "../../components";
import AddImage from "../../Tools/AddImage/AddImage";
import { fileUrl, useFETCH, usePOST } from "../../Tools/APIs/useMyAPI";
import JoditEditor from "jodit-react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AddFiles from "../../components/AddFiles/AddFiles";
import Selected from "../../components/Input/Selected";
function AddBlogs() {
  const { id } = useParams();
  const { data } = useFETCH(id ? `admin/blogs/${id}` : `admin/blogs`);
  const { data: datacat } = useFETCH(`admin/categories`);
  let dataAllCat = datacat?.data?.data;

  // console.log(dataAllCat);
  const [oldImages, setoldImages] = useState({});

  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit(`admin/blogs`);
  };

  let dataOld = data?.data?.data;
  const [userImages, setUserImages] = useState(dataOld?.data?.data?.images);

  const [userImage, setUserImage] = useState(dataOld?.image);
  useEffect(() => {
    setoldImages(dataOld?.files);
  }, []);

  useEffect(() => {
    setFormData({
      en_title: dataOld?.en_title || "",
      ar_title: dataOld?.ar_title || "",
      en_description: dataOld?.en_description || "",
      ar_description: dataOld?.ar_description || "",
      category_id: dataOld?.category_id || "",
    });
  }, [dataOld]);

  // useEffect(() => {
  //   if (successfulPost) {
  //     setImages({});
  //     setCounter(0);
  //     setFormData({});
  //   }
  // }, [successfulPost]);

  useEffect(() => {
    setUserImage(dataOld?.image);
  }, [dataOld?.image]);
  const {
    setFormData,
    viewImages,
    setViewImages,
    handleChangeArrayImages,
    handleChangeInput,
    handleSubmit,
    setImages,
    formData,
    loading,
    counter,
    setCounter,
    successfulPost,
    images,
    viewFile,
    setViewFile,
  } = usePOST();
  return (
    <>
      <Row>
        <Col lg={11} className="mx-auto">
          <h1 className="mb-4 text-2xl text-white font-bold text-start max-md:text-lg">
            أضف صورة المقال{" "}
          </h1>
          <AddImage
            name="image"
            newImage={viewFile}
            oldImage={userImage && fileUrl + userImage}
            clickDeleteOldImage={() => {
              setUserImage("");
            }}
            onChange={handleChangeInput}
            clickDeleteImage={() => {
              setViewFile("");
              setFormData({ ...formData, image: "" });
            }}
          />
        </Col>
        {/* <Col lg={11} className="mx-auto">
          <h1 className="mb-4 text-2xl text-white font-bold text-start max-md:text-lg">
            أضف صور المشروع{" "}
          </h1>
          <AddFiles
            type="IMAGES"
            name="files[]"
            newImages={viewImages}
            setnewImages={setViewImages}
            setFormData={setFormData}
            onChange={handleChangeArrayImages}
            setImagesToSend={setImages}
            formData={formData}
            counter={counter}
            setCounter={setCounter}
            setOldImages={setoldImages}
            viewImages={viewImages}
            setViewImages={setViewImages}
            images={oldImages}
            setUserImages={setUserImages}
            imagesToSend={images}
            id={"files"}
          />
        </Col> */}

        <Col lg={11} className="mx-auto ">
          <Input
            type="text"
            name="en_title"
            value={formData?.en_title}
            onChange={handleChangeInput}
            title="اسم المقال باللغة الانكليزية "
            className="mb-4 text-2xl  font-bold text-start max-md:text-lg"
          />
        </Col>
        <Col lg={11} className="mx-auto ">
          <Input
            type="text"
            name="ar_title"
            value={formData?.ar_title}
            onChange={handleChangeInput}
            title="اسم المقال باللغة العربية "
            className="mb-4 text-2xl  font-bold text-start max-md:text-lg"
          />
        </Col>
        <Col lg={11} className="mx-auto ">
          <Selected
            text={"اختر صنف المقالة"}
            name={"category_id"}
            value={formData?.category_id}
            onChange={handleChangeInput}
            className="mb-4 text-2xl  font-bold text-start max-md:text-lg"
          >
            {dataAllCat?.map((e) => (
              <option value={e.id}>
                {e.ar_name}-{e.en_name}
              </option>
            ))}
          </Selected>
        </Col>
        <Col lg={11} className="mx-auto ">
          <h1 className="mb-4 text-2xl text-white font-bold">
            وصف المقال باللغة الانكليزية
          </h1>
          <JoditEditor
            value={formData?.en_description}
            onChange={(e) => setFormData({ ...formData, en_description: e })}
          />
        </Col>
        <Col lg={11} className="mx-auto ">
          <h1 className="mb-4 text-2xl text-white font-bold">
            وصف المقال باللغة العربية
          </h1>
          <JoditEditor
            value={formData?.ar_description}
            onChange={(e) => setFormData({ ...formData, ar_description: e })}
          />
        </Col>
      </Row>
      <Row className="flex justify-center mx-auto !mt-12">
        <Col md={2}>
          <Back />
        </Col>
        <Col md={2}>
          <MainButton name="حفظ" onClick={handleSubmitMain} />
        </Col>
      </Row>
    </>
  );
}
export default AddBlogs;
