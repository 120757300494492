import { useContextHook } from "../../Context/ContextOPen";
import { NavLink } from "react-router-dom";
import logo from "../../images/Logo.png";
import { Link } from "react-router-dom";
import { logout } from "../../Tools/APIs/useMyAPI";
import logoutIcon from "../../Svg/logout.svg";
import { Popup } from "../../components";
import "./SideBar.css";
import { FaBloggerB, FaHome } from "react-icons/fa";
import { BiMessageDetail } from "react-icons/bi";
import { TiContacts } from "react-icons/ti";
import {
  MdOutlinePassword,
  MdOutlineDesignServices,
  MdRoundaboutLeft,
} from "react-icons/md";
import { SiNounproject } from "react-icons/si";
import { VscServerProcess } from "react-icons/vsc";
function SideBar() {
  const { openMenu, changeMenu } = useContextHook();
  const sidebar = [
    { Icon: FaHome, name: "الصفحة الرئيسية", link: "/" },
    { Icon: MdRoundaboutLeft, name: "معلومات عنا", link: "/about-us" },
    { Icon: MdOutlineDesignServices, name: "خدماتنا", link: "/our-services" },
    { Icon: SiNounproject, name: "مشاريعنا", link: "/our-project" },
    { Icon: FaBloggerB, name: "المنتدى", link: "/blogs" },
    { Icon: MdRoundaboutLeft, name: "الأصناف", link: "/categories" },
    { Icon: MdRoundaboutLeft, name: "الخدمات المطلوبة", link: "/servicestype" },

    { Icon: TiContacts, name: " الطلبات", link: "/hire-us" },
    { Icon: TiContacts, name: "تواصل معنا", link: "/contact-us" },
    { Icon: BiMessageDetail, name: "الرسائل", link: "/Message" },

    {
      Icon: MdOutlinePassword,
      name: "تغيير كلمة السر",
      link: "/Change-Password",
    },
  ];
  return (
    <>
      <div
        className={`${
          openMenu
            ? "!w-[220px] max-sm:!w-[250px] !max-lg:translate-x-10 !max-lg:w-3/4 -!right-10"
            : " w-[0px]"
        } !max-lg:translate-x-full bg-yellow-400 sidebar lg:h-[95vh] h-[100vh] mt-0 lg:mt-5 lg:rounded-2xl sideBar max-lg:h-[100%] sticky max-lg:fixed lg:left-10 left-0 lg:right-10 right-0 top-0 transition-all overflow-y-auto py-4 z-40 max-lg:z-50 `}
      >
        <div className="px-3">
          <ul className="text-white font-semibold space-y-2">
            <li className="">
              <Link to="/">
                <img src={logo} alt="" className="m-2 mx-auto object-contain" />
              </Link>
            </li>

            {sidebar.map((e, i) => (
              <li className="" key={i}>
                <NavLink
                  to={e.link}
                  className="flex items-center gap-2 py-2 px-2 rounded-lg text-sm text-[#6F757E] hover:bg-Main hover:text-white transition-all"
                >
                  <e.Icon size={20} />
                  <p>{e.name}</p>
                </NavLink>
              </li>
            ))}
            <li>
              <Popup
                id={`logout`}
                text="تسجيل خروج"
                description="هل انت متأكد من تسجيل الخروج ؟"
                onClick={() => logout("admin/logout")}
                hover={true}
              >
                <div className="flex justify-center items-center gap-2 cursor-pointer">
                  <img src={logoutIcon} alt="" className="mt-2" />
                  <p className="text-Secondary">تسجيل خروج</p>
                </div>
              </Popup>
            </li>
          </ul>
        </div>
      </div>
      <div
        onClick={changeMenu}
        style={{ transition: "0.4s" }}
        className={`${
          openMenu ? "" : "hidden"
        }  max-lg:w-full h-full bg-black/20 fixed -left-0 top-0 transition-all z-40`}
      />
    </>
  );
}

export default SideBar;
