import { Col, Row } from "../../Tools/Grid-system";
import { MainButton, Title } from "../../components";
import { FaFacebook, FaInstagram, FaTelegramPlane } from "react-icons/fa";
import { SiLinkedin } from "react-icons/si";
import { MdLocationOn, MdOutlineMailOutline } from "react-icons/md";
import { FiGithub } from "react-icons/fi";
import { BsFillTelephoneFill } from "react-icons/bs";
import { useFETCH } from "../../Tools/APIs/useMyAPI";
import { MdOutlineWhatsapp } from "react-icons/md";

const ContactUs = () => {
  const { data, isLoading } = useFETCH(`admin/info`);

  let dataAll = data?.data;

  // console.log(dataAll);

  const ContactUs = [
    {
      link: dataAll?.social_media?.instagram,
      icon: FaInstagram,
      color: "#CB3878",
      name: dataAll?.contact_us[0],
    },
    {
      link: dataAll?.social_media?.facebook,
      icon: FaFacebook,
      color: "#3B5998",
      name: dataAll?.contact_us[1],
    },
    {
      link: dataAll?.social_media?.linkedin,
      icon: SiLinkedin,
      color: "#36527c",
      name: dataAll?.contact_us[2],
    },
    {
      link: dataAll?.social_media?.telegram,
      icon: FaTelegramPlane,
      color: "#514B36",
      name: dataAll?.contact_us[3],
    },
    {
      link: dataAll?.social_media?.whatsapp,
      icon: MdOutlineWhatsapp,
      color: "green",
      name: dataAll?.contact_us[3],
    },
    {
      link: dataAll?.email,
      icon: MdOutlineMailOutline,
      color: "#707070",
      name: dataAll?.email,
    },
    {
      link: dataAll?.telephone,
      icon: BsFillTelephoneFill,
      color: "#BE6464",
      name: dataAll?.telephone,
    },
    {
      link: dataAll?.location?.en,
      icon: MdLocationOn,
      color: "#8F68A7",
      name: dataAll?.location?.en,
    },
  ];

  return (
    <div>
      <Row>
        <Col className="flex justify-center">
          <Title name=" تواصل معنا " />
        </Col>
      </Row>
      <Row justify="center">
        {ContactUs.map((contact, contactIndex) => (
          <Col md={8} key={contactIndex}>
            <div className="flex items-center justify-between border-2 border-Main rounded-xl shadow-md font-semibold py-3 px-3 bg-white">
              <a href={contact.link} target="_blank" rel="noreferrer">
                <contact.icon size={40} color={contact.color} />
              </a>
              <a href={contact.link} target="_blank" rel="noreferrer">
                <p className="font-semibold px-2 break-all">{contact.link}</p>
              </a>
            </div>
          </Col>
        ))}
      </Row>
      <div className="fixed bottom-10 left-10">
        <MainButton
          link="/Contact-Us/edit"
          name="تعديل"
          className="px-7 py-2"
        />
      </div>
    </div>
  );
};

export default ContactUs;
