import React from "react";
import { Col, Row } from "../../Tools/Grid-system";
import { Add, Delete, Show, Title } from "../../components";
import Update from "../../components/Operation/Update";
import { useFETCH } from "../../Tools/APIs/useMyAPI";
import Loading from "../../Tools/Loading/Loading";

function Categories() {
  const { data, isLoading, deleteItem } = useFETCH(
    `admin/categories`,
    `admin/categories`
  );
  let dataAll = data?.data?.data;
  console.log(data);
  return (
    <>
      <Row>
        <Col className="flex justify-center">
          <Title name="الاصناف" />
        </Col>
      </Row>
      {isLoading ? <Loading /> : ""}
      <Row justify={"between"} className="pt-5">
        {dataAll?.map((e, id) => (
          <Col key={e.id} md={4} className="flex justify-center p-3">
            <div className="w-full bg-Main rounded-full flex-col flex justify-center  items-center p-7 space-y-4">
              <p className="text-white font-bold text-lg max-md:text-base max-sm:text-xs  ">
                اسم الصنف : {e.ar_name}
              </p>
              <p className="text-white font-bold text-lg max-md:text-base max-sm:text-xs  ">
                Product Name : {e.en_name}
              </p>
              <div className={`flex justify-between gap-5 mt-4 `}>
                {<Update add={`add/${e.id}`} />}
                {
                  <Delete
                    description={e.ar_name}
                    deleteClick={() => deleteItem(e)}
                  />
                }
              </div>
            </div>
          </Col>
        ))}
      </Row>
      <Add link="add" />
    </>
  );
}

export default Categories;
