import { Route, Routes, useLocation } from "react-router-dom";
import { Container } from "./Tools/Grid-system";
import logo from "./images/Logo.png";
import { RequireAuth, useFETCH } from "./Tools/APIs/useMyAPI";
import * as component from "./components";
import * as page from "./pages";
import * as error from "./Tools/Error";
import * as layout from "./layout";
import { useContextHook } from "./Context/ContextOPen";
import { useEffect } from "react";

const App = () => {
  const { setCounter } = useContextHook();
  const { pathname } = useLocation();

  useEffect(() => {
    setCounter(0);
  }, [pathname]);

  return (
    <>
      <div className="relative flex">
        <layout.SideBar />
        {/* <component.Message /> */}
        <div className="container mx-auto relative">
          <layout.Navbar />
          <Container>
            <Routes>
              <Route path="login" element={<page.Login />} />
              <Route path="forget-password" element={<page.ForgetPassword />} />
              <Route path="verification-code" element={<page.Code />} />
              <Route path="set-password" element={<page.SetPassword />} />
              <Route
                path=""
                element={
                  <h1 className="grid place-content-center h-[75vh]">
                    <img
                      src={logo}
                      alt=""
                      className="w-[450px] h-[350px] px-8 !object-contain max-md:w-[250px] bg-white rounded-3xl shadow-xl border-t"
                    />
                  </h1>
                }
              />
              <Route path="*" element={<error.P404 />} />
              <Route path="403" element={<error.P403 />} />
              <Route path="500" element={<error.P500 />} />
              <Route element={<RequireAuth />}>
                {}
                <Route path="contact-us">
                  <Route index element={<page.ContactUs />} />
                  <Route path=":id" element={<page.UpdateContact />} />
                </Route>
                <Route path="about-us">
                  <Route index element={<page.AboutUs />} />
                  <Route path="addabout" element={<page.AddAboutUs />} />
                  <Route path="addjobs" element={<page.AddOurJobs />} />
                </Route>
                <Route path="categories">
                  <Route index element={<page.Categories />} />
                  <Route path="add" element={<page.AddCategories />} />
                  <Route path="add/:id" element={<page.EditCategories />} />
                </Route>
                <Route path="servicestype">
                  <Route index element={<page.ServicesType />} />
                  <Route path="add" element={<page.AddServicesType />} />
                  <Route path="add/:id" element={<page.EditServicesType />} />
                </Route>
                <Route path="our-services">
                  <Route index element={<page.Services />} />
                  <Route path="add" element={<page.AddServices />} />
                  <Route path=":id" element={<page.Service />} />
                  <Route path="add/:id" element={<page.AddService />} />
                </Route>
                <Route path="our-project">
                  <Route index element={<page.Projects />} />
                  <Route path="add" element={<page.AddProjects />} />
                  <Route path=":id" element={<page.Project />} />
                  <Route path="add/:id" element={<page.AddProject />} />
                </Route>
                <Route path="process">
                  <Route index element={<page.Process />} />
                  <Route path=":id" element={<page.AddProcess />} />
                </Route>
                <Route path="blogs">
                  <Route index element={<page.Blogs />} />
                  <Route path="add" element={<page.AddBlogs />} />
                  <Route path=":id" element={<page.Blog />} />
                  <Route path="add/:id" element={<page.AddBlog />} />
                </Route>
                <Route path="/" element={<page.Home />} />
                <Route path="message" element={<page.Messages />} />
                <Route path="hire-us" element={<page.HireUs />} />

                <Route
                  path="Change-Password"
                  element={<page.ChangePassword />}
                />
              </Route>
            </Routes>
          </Container>
        </div>
      </div>
    </>
  );
};

export default App;
