import { BiMenu } from "react-icons/bi";
import { useContextHook } from "../../Context/ContextOPen";
import logoutIcon from "../../Svg/logout.svg";
import { Popup, Search } from "../../components";
import logo from "../../images/Logo.png";
import ImageList from "./ImageList";
import { logout } from "../../Tools/APIs/useMyAPI";
const Navbar = () => {
  const { changeMenu } = useContextHook();
  return (
    <>
      <div className="sticky lg:mx-12 bg-yellow-400 top-0 z-20 flex items-center max-sm:flex-wrap max-sm:gap-1 justify-between px-8 py-2 rounded-2xl">
        <div>
          <BiMenu
            size={40}
            className="cursor-pointer text-white"
            onClick={changeMenu}
            color="blue"
          />
        </div>
        <div className="max-sm:order-2">
          <img src={logo} alt="" className="" />
        </div>
        <Popup
          id={`logout`}
          text="تسجيل خروج"
          description="هل انت متأكد من تسجيل الخروج ؟"
          onClick={() => logout("admin/logout")}
          hover={true}
        >
          <div className="flex justify-center items-center gap-2 cursor-pointer max-sm:hidden">
            <img src={logoutIcon} alt="" className="mt-2" />
            <p className="text-Secondary">تسجيل خروج</p>
          </div>
        </Popup>
      </div>
    </>
  );
};

export default Navbar;
