const Input = ({
  type,
  name,
  value,
  onChange,
  placeholder,
  title,
  hideTitle,
  className,
  error,
}) => {
  return (
    <div className="py-3">
      <h1
        className={`${
          hideTitle ? "hidden" : ""
        } px-3 font-semibold text-start mx-4 mb-2 text-white  ${className}`}
      >
        {placeholder || title}
      </h1>
      <input
        type={type || "text"}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className={`border bg-[#fff] border-[#D2D2D2] m-1 py-[0.4rem] rounded-md text-blue-900  ${className}`}
      />
      {error && <div className="text-red-500 font-semibold">{error}</div>}
    </div>
  );
};
export default Input;
