import { useEffect } from "react";
import { usePOST } from "../../Tools/APIs/useMyAPI";
import { Col, Row } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading/Loading";
import { Input, MainButton } from "../../components";
import Logo from "../../images/Logo.png";
function ForgetPassword() {
  const { handleSubmit, loading, handleChangeInput, formData, error } = usePOST(
    {}
  );
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit("admin/send/verification-code", "/verification-code");
  };
  useEffect(() => {
    sessionStorage.setItem("email", formData?.email);
  }, [formData]);
  return (
    <div className="fixed w-full h-full bg-[#FFF4F4] top-0 left-0 z-50 flex items-center justify-center">
      <Col
        md={6}
        sm={11}
        className="mx-auto space-y-3 bg-white p-8 rounded-3xl border-t shadow-xl"
      >
        <img src={Logo} alt="" className="w-[300px] mx-auto object-contain" />
        <h1 className="text-xl font-semibold text-center my-2">
          نسيت كلمة المرور
        </h1>
        <Row>
          <Col md={10} className="mx-auto">
            <Input
              name="email"
              onChange={handleChangeInput}
              placeholder="ادخل الإيميل"
              className="bg-white w-full border-[3px] my-1 !py-3 rounded-xl"
              error={error?.email}
            />
          </Col>
          <Col md={5} sm={8} className="mx-auto">
            <MainButton
              name="التالي"
              className="!px-10 mt-2 !py-2"
              onClick={handleSubmitMain}
            />
          </Col>
        </Row>
        {loading ? <Loading /> : ""}
      </Col>
    </div>
  );
}
export default ForgetPassword;
