import React from "react";
import Delete from "../Operation/Delete";
import { RiFolderDownloadFill } from "react-icons/ri";
import { Col, Row } from "../../Tools/Grid-system";
import { fileUrl } from "../../Tools/APIs/useMyAPI";
function RequestCard({
  name,
  email,
  phone,
  message,
  audience,
  services,
  system,
  start,
  end,
  budget,
  deleteClick,
  className,
  description,
  file,
}) {
  return (
    <div className="w-full h-full bg-slate-100 rounded-3xl border-2 border-black space-y-3 py-3 ">
      <div className={`flex justify-center ${className}`}>
        {deleteClick && (
          <Delete deleteClick={deleteClick} description={description} />
        )}
      </div>
      <p className="text-lg text-Main  px-6 text-start font-semibold max-md:text-sm">
        <span className="text-yellow-700 font-bold">الاسم : </span>{" "}
        {name || "_____"}
      </p>
      <p className="text-lg text-Main  px-6 text-start font-semibold max-md:text-sm">
        <span className="text-yellow-700 font-bold">البريد الالكتروني : </span>{" "}
        {email || "_____"}
      </p>
      <p className="text-lg text-Main  px-6 text-start font-semibold max-md:text-sm">
        <span className="text-yellow-700 font-bold">رقم الهاتف : </span>{" "}
        {phone || "_____"}
      </p>
      <p className="text-lg text-Main  px-6 text-start font-semibold max-md:text-sm">
        <span className="text-yellow-700 font-bold"> الرسالة : </span>{" "}
        {message || "_____"}
      </p>
      <p className="text-lg text-Main  px-6 text-start font-semibold max-md:text-sm">
        <span className="text-yellow-700 font-bold"> الجمهور المستهدف : </span>{" "}
        {audience || "_____"}
      </p>

      <p className="text-lg text-Main  px-6 text-start font-semibold max-md:text-sm">
        <span className="text-yellow-700 font-bold">الخدمات المطلوبة : </span>{" "}
        {services || "_____"}
      </p>
      <p className="text-lg text-Main  px-6 text-start font-semibold max-md:text-sm ">
        <span className="text-yellow-700 font-bold">نمط النظام : </span>{" "}
        {system + " " || "_____"}
      </p>
      <p className="text-lg text-Main  px-6 text-start font-semibold max-md:text-sm">
        <span className="text-yellow-700 font-bold">
          {" "}
          التاريخ المتوقع للبدء :{" "}
        </span>{" "}
        {start || "_____"}
      </p>
      <p className="text-lg text-Main  px-6 text-start font-semibold max-md:text-sm">
        <span className="text-yellow-700 font-bold">
          {" "}
          التاريخ المتوقع للانتهاء :{" "}
        </span>{" "}
        {start || "_____"}
      </p>
      <p className="text-lg text-Main  px-6  text-start font-semibold max-md:text-sm">
        <span className="text-yellow-700 font-bold">
          {" "}
          الميزانية المتوقعة :{" "}
        </span>{" "}
        {budget || "_____"}
      </p>
      {file.name && (
        <Row justify={"center"}>
          <Col sm={9} className="flex justify-center">
            <p className="text-yellow-700 font-bold">ملف الطلب</p>
          </Col>
          <Col sm={9} className="flex justify-center">
            <a href={fileUrl + file.name} download={file.type}>
              <RiFolderDownloadFill
                color="blue"
                size={100}
                className="rounded-full"
              />
            </a>
          </Col>
        </Row>
      )}
    </div>
  );
}

export default RequestCard;
