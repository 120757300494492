import React from "react";
import { Col, Row } from "../../Tools/Grid-system";
import { RequestCard, Title } from "../../components";
import useFilter, { useFETCH } from "../../Tools/APIs/useMyAPI";
import Loading from "../../Tools/Loading/Loading";
import Pagination from "../../Tools/Pagination";

function HireUs() {
  const { searchParams } = useFilter();
  const { data, isLoading, deleteItem } = useFETCH(
    `admin/posts?page=${searchParams.get("page")} `,
    `admin/posts`
  );

  let dataAll = data?.data?.data;
  console.log(dataAll);

  // console.log(dataAll);
  return (
    <>
      <Row>
        <Col className="flex justify-center">
          <Title name="الطلبات" />
        </Col>
      </Row>
      {isLoading ? <Loading /> : ""}
      <Pagination
        pageCount={Math.ceil(data?.data?.total / data?.data?.per_page)}
      >
        <Row>
          {dataAll?.map((e, id) => (
            <Col key={id} lg={6}>
              <RequestCard
                className="!flex !justify-end"
                name={e.name}
                email={e.email}
                phone={e.phone}
                message={e.message}
                audience={e.target_audience}
                services={e.service_types.map((e) => e.ar_name || e.en_name)}
                system={e.system_types.map((e) => e.ar_name || e.en_name)}
                start={e.expected_starting_date}
                end={e.expected_ending_date}
                budget={e.expected_budget}
                deleteClick={() => deleteItem(e)}
                description={e.service_types.map((e) => e.ar_name || e.en_name)}
                file={e.file}
              />
            </Col>
          ))}
        </Row>
      </Pagination>
    </>
  );
}

export default HireUs;
