import useFilter, { useFETCH } from "../../Tools/APIs/useMyAPI";
import { Col, Row } from "../../Tools/Grid-system";
import { Add, Table, Title } from "../../components";

function Projects() {
  const { searchParams } = useFilter();
  const { data, deleteItem, isLoading } = useFETCH(
    `admin/projects?page=${searchParams.get("page")}`,
    `admivn/projects`
  );
  let dataAll = data?.data?.data;
  // console.log(dataAll);

  return (
    <>
      <Row>
        <Col className="flex justify-center">
          <Title name="مشاريعنا" />
        </Col>
      </Row>
      <Row>
        <Col>
          <Table
            thead={[
              "رقم المشروع",
              "صورة المشروع",
              "الوصف باللغة العربية",
              "الوصف باللغة العربية",
              "الاسم باللغة الانكليزية",
              "الاسم باللغة العربية ",
            ]}
            pageCount={Math.ceil(data?.data?.total / data?.data?.per_page)}
            tData={dataAll}
            tbody={[
              "id",
              "img",
              "ar_description",
              "en_description",
              "ar_name",
              "en_name",
            ]}
            funDelete={deleteItem}
            edit={true}
            add="our-project/add"
            show={`our-project`}
            description={dataAll?.map((e) => e.ar_name)}
          />
        </Col>
      </Row>
      <Add link="add" />
    </>
  );
}
export default Projects;
