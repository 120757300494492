import { useParams } from "react-router-dom";
import { clickZoomInImage, fileUrl, useFETCH } from "../../Tools/APIs/useMyAPI";
import Pagination from "../../Tools/Pagination";
import Add from "../Buttons/Add";
import Delete from "../Operation/Delete";
import Show from "../Operation/Show";
import Update from "../Operation/Update";
import parse from "html-react-parser";

const Table = ({
  thead,
  tbody,
  tData,
  show,
  funDelete,
  pageCount,
  edit,
  hide,
  showContent,
  isLoading,
  description,
  add,
}) => {
  return (
    <div>
      <Pagination isLoading={isLoading} pageCount={pageCount}>
        <div className="mx-auto w-full py-4 overflow-x-auto h-full pb-5">
          <table className="w-full mx-auto border overflow-hidden rounded-2xl h-full bg-white ">
            <thead className="w-full text-center">
              <tr className="border-2  border-yellow-400">
                {thead?.map((e, i) => (
                  <td
                    key={i}
                    className="py-3 px-5 text-sm font-semibold border-2  border-yellow-400"
                  >
                    {e}
                  </td>
                ))}
                <td
                  className={`${
                    hide ? "hidden" : "p-5 w-fit"
                  } border-2  border-yellow-400`}
                >
                  العمليات
                </td>
              </tr>
            </thead>
            <tbody className=" text-center ">
              {tData &&
                tData?.map((e, i) => (
                  <tr key={i}>
                    {tbody?.map((name, i) => (
                      <>
                        <td
                          key={i}
                          className={`py-1 px-1 text-[#8C9198] max-md:text-sm border`}
                        >
                          {typeof e[name] === "string" &&
                          e[name].length > 15 ? (
                            <div className="">
                              {e[name].substring(0, 15)}...
                            </div>
                          ) : typeof e[name] === "text" &&
                            typeof e[name] === "img" ? (
                            <img
                              src={e[name]}
                              alt=""
                              className="w-14 h-14 rounded-full mx-auto cursor-pointer"
                              onClick={clickZoomInImage}
                            />
                          ) : e[name] ? (
                            parse(`<div>${e[name]}</div>`)
                          ) : (
                            <img
                              src={fileUrl + e.image}
                              alt=""
                              className="w-14 h-14 rounded-full mx-auto cursor-pointer border-blue-100"
                              onClick={clickZoomInImage}
                            />
                          )}
                        </td>
                      </>
                    ))}
                    <td
                      className={`${
                        hide ? "hidden" : "cursor-pointer relative"
                      } border-2  border-yellow-400`}
                    >
                      <div className="flex justify-center items-center gap-3">
                        {edit && <Update add={`/${add}/${e.id}`} />}

                        {show && (
                          <Show show={`/${show}/${e.id}`} text={showContent} />
                        )}
                        {funDelete && (
                          <Delete
                            deleteClick={() => funDelete(e)}
                            description={description}
                          />
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </Pagination>
    </div>
  );
};

export default Table;
