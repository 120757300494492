import { useEffect, useState } from "react";
import { Back, Input, MainButton, Title } from "../../components";
import { Col, Row } from "../../Tools/Grid-system";
import useFilter, { useFETCH, usePOST } from "../../Tools/APIs/useMyAPI";
import Loading from "../../Tools/Loading/Loading";
import AddFiles from "../../components/AddFiles/AddFiles";
import ContactUs from "./ContactUs";

function UpdateContact() {
  const { searchParams } = useFilter();

  const { data, isLoading } = useFETCH(`admin/info `);
  // console.log(data);
  const [oldImages, setoldImages] = useState({});

  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit(`admin/info/update`);
  };

  let dataOld = data?.data?.social_media;
  let dataOld_1 = data?.data;
  let dataOld_2 = data?.data?.location;

  // console.log(dataOld_1);
  const [userImages, setUserImages] = useState(dataOld?.data?.data?.images);

  const [userImage, setUserImage] = useState(dataOld?.image);
  useEffect(() => {
    setoldImages(dataOld?.files);
  }, []);

  useEffect(() => {
    setFormData({
      instagram: dataOld?.instagram || "",
      facebook: dataOld?.facebook || "",
      linkedin: dataOld?.linkedin || "",
      telegram: dataOld?.telegram || "",
      whatsapp: dataOld?.whatsapp || "",
      email: dataOld_1?.email || "",
      telephone: dataOld_1?.telephone || "",
      location: dataOld_2?.ar || dataOld_2?.en,
    });
  }, [dataOld, dataOld_1, dataOld_2]);

  // useEffect(() => {
  //   if (successfulPost) {
  //     setImages({});
  //     setCounter(0);
  //     setFormData({});
  //   }
  // }, [successfulPost]);

  useEffect(() => {
    setUserImage(dataOld?.image);
  }, [dataOld?.image]);

  const {
    setFormData,
    viewImages,
    setViewImages,
    handleChangeArrayImages,
    handleChangeInput,
    handleSubmit,
    setImages,
    formData,
    loading,
    counter,
    setCounter,
    successfulPost,
    images,
    viewFile,
    setViewFile,
  } = usePOST();

  const dataInput = [
    {
      type: "string",
      name: "instagram",
      placeholder: "الإنستاغرام",
    },
    {
      type: "string",
      name: "facebook",
      placeholder: "الفيس بوك",
    },
    {
      type: "string",
      name: "linkedin",
      placeholder: "لينكد ان",
    },
    {
      type: "string",
      name: "telegram",
      placeholder: " تيليغرام",
    },
    {
      type: "string",
      name: "whatsapp",
      placeholder: "واتس اب",
    },
    {
      type: "string",
      name: "email",
      placeholder: " البريد الالكتروني ",
    },
    {
      type: "string",
      name: "telephone",
      placeholder: "رقم التلفون",
    },
    {
      type: "string",
      name: "location",
      placeholder: " الموقع",
    },
  ];
  return (
    <div>
      <Title title="تعديل تواصل معنا" />
      <Row className="p-4 justify-center border-2 border-Secondary rounded-2xl">
        {dataInput.map((e, i) => {
          return (
            <Col md={6} key={i}>
              <Input
                type={e.type}
                name={e.name}
                placeholder={e.placeholder}
                value={formData?.[e.name]}
                onChange={handleChangeInput}
                className=""
              />
            </Col>
          );
        })}
        <Col lg={11} className="mx-auto">
          <h1 className="mb-4 text-2xl text-white font-bold text-start max-md:text-lg">
            أضف ملف البورتفوليو{" "}
          </h1>
          <AddFiles
            type="IMAGES"
            name="files[]"
            newImages={viewImages}
            setnewImages={setViewImages}
            setFormData={setFormData}
            onChange={handleChangeArrayImages}
            setImagesToSend={setImages}
            formData={formData}
            counter={counter}
            setCounter={setCounter}
            setOldImages={setoldImages}
            viewImages={viewImages}
            setViewImages={setViewImages}
            images={userImages}
            setUserImages={setUserImages}
            imagesToSend={images}
            id={"files"}
          />
        </Col>
        {loading || isLoading ? <Loading /> : ""}
        <Row className="flex justify-center mx-auto">
          <Col md={2}>
            <Back />
          </Col>
          <Col md={2}>
            <MainButton name="حفظ" onClick={handleSubmitMain} />
          </Col>
        </Row>
      </Row>
    </div>
  );
}
export default UpdateContact;
