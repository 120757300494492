import deleted from "../../Svg/delete.svg";
import Popup from "../Popup/Popup";
const Delete = ({ deleteClick, description }) => {
  return (
    <div>
      <Popup
        text="حذف"
        description={ "هل تريد حذف هذا العنصر " + description }     
        onClick={deleteClick}
      >
        <img src={deleted} alt="" className="text-red-600 " />
      </Popup>
    </div>
  );
};

export default Delete;
