const Title = ({ name, className, load }) => {
  return (
    <div
      data-aos="fade-down"
      data-aos-easing="linear"
      data-aos-duration="1500"
      className="text-4xl text-start font-bold  decoration-Secondary decoration-4 my-10 flex  justify-start gap-5 max-sm:text-xl"
    >
      <p className="w-[6px] h-[40px] bg-Secondary"></p> 
      <p className="text-white">{name}</p>
    </div>
  );
};

export default Title;
