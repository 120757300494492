import { MdAddCircle } from "react-icons/md";
import { Link } from "react-router-dom";
const Add = ({ link }) => {
  return (
    <Link to={link || ""}>
      <MdAddCircle
        size={80}
        className={`
         z-10 active:-rotate-45 transition fixed bottom-10 left-2 text-Secondary`}
      />
    </Link>
  );
};

export default Add;
