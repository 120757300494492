import { useParams } from "react-router-dom";
import useFilter, { fileUrl, useFETCH } from "../../Tools/APIs/useMyAPI";
import { Col, Row } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading/Loading";
import { CardAll, Title } from "../../components";

function AboutUs() {
  const { id } = useParams();
  const { data, isLoading } = useFETCH(`admin/info`);
  let dataAll = data?.data;
  console.log(data);

  return (
    <>
      <Row>
        <Col className="flex justify-center">
          <Title name="معلومات عنا " />
        </Col>
      </Row>
      {isLoading ? <Loading /> : ""}
      <Row justify={"center"}>
        <Col md={6} className="flex justify-center ">
          <CardAll
            image={fileUrl + dataAll?.about?.about_image}
            title="About Us"
            edit={`addabout`}
            p={dataAll?.about.about_text.en.substring(0, 100) + "..."}
            title_ar="معلومات عنا"
            p_ar={dataAll?.about?.about_text.ar.substring(0, 100) + "..."}
          />
        </Col>
        <Col md={6} className="flex justify-center">
          <CardAll
            image={fileUrl + dataAll?.our_job?.our_job_image}
            title="Our mission"
            edit={`addjobs`}
            p={dataAll?.our_job?.our_job_text.en.substring(0, 100) + "..."}
            title_ar=" مهمتنا"
            p_ar={dataAll?.our_job?.our_job_text.ar.substring(0, 100) + "..."}
          />
        </Col>
      </Row>
    </>
  );
}
export default AboutUs;
