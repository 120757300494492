import { useLocation } from "react-router-dom";
import useFilter, { useFETCH } from "../../Tools/APIs/useMyAPI";
import { Col, Container, Row } from "../../Tools/Grid-system";
import Pagination from "../../Tools/Pagination";
import { CardAll, Data, Title } from "../../components";
import Loading from "../../Tools/Loading/Loading";

const Messages = () => {
  const { searchParams } = useFilter();
  const { data, isLoading, deleteItem } = useFETCH(
    `admin/messages?page=${searchParams.get("page")} `,
    `admin/messages`
  );
  let dataAll = data?.data?.data;
  // console.log(dataAll);

  return (
    <Container>
      <Row>
        <Col className="flex justify-center">
          <Title name=" الرسائل" />
        </Col>
      </Row>
      {isLoading ? <Loading /> : ""}
      <Pagination
        pageCount={Math.ceil(data?.data?.total / data?.data?.per_page)}
      >
        <Row>
          {dataAll?.map((e, key) => (
            <Col key={e.id} lg={6} md={6} className="flex justify-center p-4 ">
              <CardAll
                deleteClick={() => deleteItem(e)}
                description={e.name}
                className="!flex !justify-end "
              >
                <Data name="الاسم" text={e.name} />
                <Data name="الموقع" text={e.email} />
                <Data name="الرسالة" text={e.message} />
              </CardAll>
            </Col>
          ))}
        </Row>
      </Pagination>
    </Container>
  );
};

export default Messages;
