import React, { createContext, useContext, useState } from "react";

const ContextOpen = createContext({});

const ContextProvider = ({ children }) => {
  const [openMenu, setOpenMenu] = useState(true);
  const [content, setContent] = useState("");
  const [message, setMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [counter, setCounter] = useState(0);
  const changeMenu = () => {
    setOpenMenu(!openMenu);
  };
  return (
    <ContextOpen.Provider
      value={{
        openMenu,
        changeMenu,
        content,
        setContent,
        setMessage,
        message,
        successMessage,
        setSuccessMessage,
        setCounter,
        counter,
      }}
    >
      {children}
    </ContextOpen.Provider>
  );
};

export default ContextProvider;

export const useContextHook = () => {
  return useContext(ContextOpen);
};
