import ButtonCard from "../Buttons/ButtonCard";

import Delete from "../Operation/Delete";
import Show from "../Operation/Show";
import Update from "../Operation/Update";

import parse from "html-react-parser";

const CardAll = ({
  children,
  deleteClick,
  edit,
  className,
  show,
  text,
  image,
  p,
  title,
  title_ar,
  p_ar,
  buttoncard,
  description,
  button,
}) => {
  return (
    <>
      <div
        className={`bg-gradient-to-l from-Main to-Secondary w-full h-full rounded-ee-[40px] rounded-ss-[100px] rounded-se-[60px] relative shadow-md  ${className}`}
      >
        <div className="bg-white w-full h-full rounded-ee-[100px] rounded-ss-[120px] rounded-se-[60px] mx-auto flex-col pt-2 pb-8 space-y-2">
          <div className="flex justify-center">
            {image && (
              <img src={image} alt="" className="h-36  rounded-full    " />
            )}
          </div>
          <div className={`flex justify-center gap-5 ${className}`}>
            {show && <Show show={show} text={text} />}
            {edit && <Update add={edit} />}
            {deleteClick && (
              <Delete deleteClick={deleteClick} description={description} />
            )}
          </div>
          <div>
            {title && (
              <p className="text-center text-2xl font-bold max-md:text-base text-Main">
                {title}
              </p>
            )}
          </div>
          <div>
            {p && (
              <p className="text-sm text-black font-semibold  text-center mx-1 max-md:text-sm ">
                {"..." + p.substring(0, 2) && parse(p)}
              </p>
            )}
          </div>
          <div className="border border-b-Main w-[80%] mx-auto"></div>
          <div>
            {title_ar && (
              <p className="text-center text-2xl font-bold max-md:text-base  text-Main">
                {title_ar}
              </p>
            )}
          </div>
          <div>
            {p_ar && (
              <p className="text-sm text-black font-semibold  text-center mx-1 max-md:text-sm ">
                {p_ar.substring(0, 20) + "..." && parse(p_ar)}
              </p>
            )}
          </div>
          <div>{buttoncard && <ButtonCard button={buttoncard} />}</div>

          <div>{children}</div>
        </div>
      </div>
    </>
  );
};
export default CardAll;
