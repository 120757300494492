import React, { useEffect, useState } from "react";
import { Col, Row } from "../../Tools/Grid-system";
import { Back, Input, MainButton } from "../../components";
import { useParams } from "react-router-dom";
import { useFETCH, usePOST } from "../../Tools/APIs/useMyAPI";

function EditCategories() {
  const { id } = useParams();
  const { data } = useFETCH(`admin/categories/${id}`);
  //   console.log(data);

  let dataOld = data?.data?.data;

  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit(id ? `admin/categories/${id}` : "admin/categories");
  };

  useEffect(() => {
    setFormData({
      en_name: dataOld?.en_name || "",
      ar_name: dataOld?.ar_name || "",
    });
  }, [dataOld]);

  const {
    setFormData,
    handleChangeInput,
    handleSubmit,
    setImages,
    formData,
    loading,
    counter,
    setCounter,
    successfulPost,
    images,
    viewFile,
    setViewFile,
  } = usePOST();
  return (
    <>
      <Row>
        <Col lg={11} className="mx-auto ">
          <Input
            type="text"
            name="ar_name"
            value={formData?.ar_name}
            onChange={handleChangeInput}
            title="اسم المشروع باللغة العربية "
            className="mb-4 text-2xl  font-bold text-start max-md:text-lg"
          />
        </Col>
        <Col lg={11} className="mx-auto ">
          <Input
            type="text"
            name="en_name"
            value={formData?.en_name}
            onChange={handleChangeInput}
            title="اسم المشروع باللغة الانكليزية "
            className="mb-4 text-2xl  font-bold text-start max-md:text-lg"
          />
        </Col>
      </Row>
      <Row className="flex justify-center mx-auto">
        <Col md={2}>
          <Back />
        </Col>
        <Col md={2}>
          <MainButton name="حفظ" onClick={handleSubmitMain} />
        </Col>
      </Row>
    </>
  );
}

export default EditCategories;
