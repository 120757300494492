import { Col, Row } from "../../Tools/Grid-system";
import { Back, Input, InputBox, MainButton, Title } from "../../components";
import AddImage from "../../Tools/AddImage/AddImage";
import { fileUrl, useFETCH, usePOST } from "../../Tools/APIs/useMyAPI";
import JoditEditor from "jodit-react";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import AddFiles from "../../components/AddFiles/AddFiles";

function AddServices() {
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit(`admin/services`);
  };

  const {
    handleChangeInput,
    setFormData,
    viewImages,
    setViewImages,
    handleChangeArrayImages,
    handleSubmit,
    setImages,
    formData,
    loading,
    counter,
    setCounter,
    successfulPost,
    images,
    setViewFile,
    viewFile,
  } = usePOST({});

  const { userImage, setUserImage } = useState("");

  useEffect(() => {
    if (successfulPost) {
      setImages({});

      setCounter(0);
      setFormData({});
    }
  }, [successfulPost]);

  useEffect(() => {
    setFormData({
      en_name: "",
      ar_name: "",
      en_description: "",
      ar_description: "",
      is_special: "",
    });
  }, []);

  useEffect(() => {
    if (successfulPost) {
      setImages({});
      setCounter(0);
      setFormData({});
    }
  }, [successfulPost]);

  // console.log(formData);

  return (
    <>
      <Row>
        <Col className="flex justify-center">
          <Title name="خدماتنا / اسم الخدمة" />
        </Col>
      </Row>
      <Row>
        <Col lg={11} className="mx-auto">
          <h1 className="mb-4 text-2xl text-white font-bold text-start max-md:text-lg">
            أضف صورة الخدمة{" "}
          </h1>
          <AddImage
            name="image"
            newImage={viewFile}
            oldImage={userImage && fileUrl + userImage}
            clickDeleteOldImage={() => {
              setUserImage("");
            }}
            onChange={handleChangeInput}
            clickDeleteImage={() => {
              setViewFile("");
              setFormData({ ...formData, image: "" });
            }}
          />
        </Col>
        <Col lg={11} className="mx-auto">
          <h1 className="mb-4 text-2xl text-white font-bold text-start max-md:text-lg">
            أضف صور الخدمة{" "}
          </h1>

          <AddFiles
            type="IMAGES"
            name="files[]"
            viewImages={viewImages}
            setViewImages={setViewImages}
            setFormData={setFormData}
            onChange={handleChangeArrayImages}
            setImagesToSend={setImages}
            formData={formData}
            counter={counter}
            setCounter={setCounter}
            imagesToSend={images}
          />
        </Col>

        <Col lg={11} className="mx-auto ">
          <Input
            type="text"
            name="ar_name"
            value={formData?.ar_name}
            onChange={handleChangeInput}
            title="اسم الخدمة باللغة العربية "
            className="mb-4 text-2xl  font-bold text-start max-md:text-lg"
          />
        </Col>

        <Col lg={11} className="mx-auto ">
          <Input
            type="text"
            name="en_name"
            value={formData?.en_name}
            onChange={handleChangeInput}
            title="اسم الخدمة باللغة الانكليزية "
            className="mb-4 text-2xl  font-bold text-start max-md:text-lg"
          />
        </Col>
        <Col lg={11} className="mx-auto pb-5">
          <Input
            type="text"
            name="ar_short_description"
            value={formData?.ar_short_description}
            onChange={handleChangeInput}
            title="وصف الخدمة المختصر باللغة العربية "
            className="mb-4 text-2xl  font-bold text-start max-md:text-lg"
          />
        </Col>
        <Col lg={11} className="mx-auto pb-5">
          <Input
            type="text"
            name="en_short_description"
            value={formData?.en_short_description}
            onChange={handleChangeInput}
            title="وصف الخدمة المختصر باللغة الانكليزية "
            className="mb-4 text-2xl  font-bold text-start max-md:text-lg"
          />
        </Col>

        <Col lg={11} className="mx-auto pb-5">
          <h1 className="mb-4 text-2xl text-white font-bold text-start max-md:text-lg">
            وصف الخدمة باللغة العربية
          </h1>
          <JoditEditor
            value={formData?.ar_description}
            onChange={(e) => setFormData({ ...formData, ar_description: e })}
          />
        </Col>
        <Col lg={11} className="mx-auto pb-5">
          <h1 className="mb-4 text-2xl text-white font-bold text-start max-md:text-lg">
            وصف الخدمة باللغة الانكليزية
          </h1>
          <JoditEditor
            value={formData?.en_description}
            onChange={(e) => setFormData({ ...formData, en_description: e })}
          />
        </Col>
      </Row>
      <Col lg={11} className="mx-auto pb-5">
        <div className=" border-2 border-Main text-l flex items-center justify-center py-2 rounded-2xl gap-2 !w-[97%]">
          <input
            type="checkbox"
            onChange={handleChangeInput}
            name="is_special"
            className="relative w-5 h-5"
            value={formData?.is_special}
          />
          <span className="text-xl font-bold text-yellow-700 ">
            {" "}
            خدمة مميزة
          </span>
        </div>
      </Col>
      <Row className="flex justify-center items-center mx-auto">
        <Col md={2}>
          <Back />
        </Col>
        <Col md={2}>
          <MainButton name="حفظ" onClick={handleSubmitMain} />
        </Col>
      </Row>
    </>
  );
}
export default AddServices;
